import { useForm } from 'react-hook-form';
import LabelForm from './LabelFrom';
import { useState } from 'react';
import { Puff } from 'react-loader-spinner'
import { promociones, servicios } from '../providers/prices';
import logoTLC from '../assets/images/logo-care-services.webp'
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";

export default function FormularioRegistro() {

    const [isLoading, setIsLoading] = useState(false)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [estimatePrice, setEstimatePrice] = useState('Choose service and pet size')

    const handleCalendarOpen = () => setIsCalendarOpen(true);
    const handleCalendarClose = () => setIsCalendarOpen(false);

    const { register, handleSubmit, formState: { errors }, watch, setValue, reset, setError, clearErrors } = useForm({
        defaultValues: {
            checkIn: formatearFecha(new Date()),
            checkOut: formatearFecha(new Date()),
            time: '',
            price: 0
        },
    })

    const onSubmit = handleSubmit(datos => {
        if (watch('size') === null) {
            setError("size", { type: "custom", message: "Choose the size of your pet" })
            return
        }
        if (watch('service') === null) {
            setError("service", { type: "custom", message: "Choose the type of service" })
            return
        }

        let datosUser = new FormData();
        datosUser.append('Fecha Solicitud', new Date())
        datosUser.append('hoja_destino', 'Boarding TLC')
        for (const key in datos) {
            switch (key) {
                case 'checkIn': datosUser.append('Check-In', datos[key])
                    break;
                case 'checkOut': datosUser.append('Check-Out', datos[key])
                    break;
                case 'name': datosUser.append('Nombre', datos[key])
                    break;
                case 'cellPhone': datosUser.append('Celular', datos[key])
                    break
                case 'promo': datosUser.append('Promo', datos[key])
                    break;
                case 'petSize': datosUser.append('Size', datos[key])
                    break;
                case 'service': datosUser.append('Servicio', datos[key])
                    break;

                default:
                    break;
            }
        }
        const scriptURL = 'https://script.google.com/macros/s/AKfycbyp760B8Z8BPzWjtHOSqWGYsC4fzKb6aTedgKdVMoRn1UGhrjSAIbsEmLyh1JReyWRXoQ/exec'
        setIsLoading(true)
        fetch(scriptURL, { method: 'POST', body: datosUser })
            .then(response => response.json()).then(data => {
                if (data.result === 'success') {
                    reset()
                    setEstimatePrice('Choose service and pet size')
                    Swal.fire({
                        title: "Booking Successful",
                        text: "An agent will contact you soon to provide details.",
                        icon: "success"
                    });
                }
            })
            .catch(error => console.error('Error!', error.message))
            .finally(() => {
                setIsLoading(false)
            })

    })

    function updatePrices(value) {
        function getPrecioByNombre(nombre) {
            const item = servicios.find(element => element.nombre === nombre);
            return item ? item.precio : 0;
        }
        const precio = getPrecioByNombre(value)
        setEstimatePrice(precio > 0 ? `$${precio}` : 'Choose service and pet size')
    }

    function formatearFecha(date) {
        let dia = date.getDate();
        let mes = date.getMonth() + 1; // Los meses comienzan desde 0
        let año = date.getFullYear();

        // Añade un cero si el día o mes es menor a 10
        dia = dia < 10 ? '0' + dia : dia;
        mes = mes < 10 ? '0' + mes : mes;

        return mes + '/' + dia + '/' + año;
    }

    return <form className='cont-form' onSubmit={onSubmit}>
        <div className='cont-text-header'>
            <img src={logoTLC} alt="Logo TLC Mobile Pet Grooming" />
        </div>
        <div className='cont-form-top-der'>

            <div className='cont-input-item' style={{ width: '100%' }}>
                <LabelForm name={'Full Name'} icono={'person'} />
                <input type='text' {...register('name', {
                    required: {
                        value: true,
                        message: 'The full name is required'
                    },
                })} />
                <span>{!!errors['name'] && errors['name'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Cell Phone'} icono={'cell'} />
                <input type='number' {...register('cellPhone', {
                    required: {
                        value: true,
                        message: 'The Cell Phone is required'
                    },
                    pattern: {
                        value: /^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{6}$/,
                        message: "The phone number is not valid"
                    }
                })} />
                <span>{!!errors['cellPhone'] && errors['cellPhone'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Pet Size'} icono={'paw'} />
                <select {...register('petSize', {
                    required: {
                        value: true,
                        message: 'Choose the size of your pet'
                    },
                    //onChange: e => updatePrices(e.target.value)
                })}>
                    <option value=''>Choose the size</option>
                    <option value='Small'>Small 1-15 Pounds</option>
                    <option value='Medium'>Medium 16-40 Pounds</option>
                    <option value='Large'>Large 41-70 Pounds</option>
                    <option value='Xlarge'>X-Lg 71-100 Pounds</option>
                </select>
                <span>{!!errors['petSize'] && errors['petSize'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Service'} icono={'paw'} />
                <select {...register('service', {
                    required: {
                        value: true,
                        message: 'Select the service'
                    },
                    onChange: e => updatePrices(e.target.value)
                })}>
                    <option value=''>Select the service</option>
                    {
                        servicios.map((servicio, index) => <option key={index} value={servicio.nombre}>{servicio.fullName}</option>)
                    }
                </select>
                <span>{!!errors['service'] && errors['service'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Promo'} icono={'gift'} />
                <select {...register('promo', {
                    required: {
                        value: true,
                        message: 'Select the promo'
                    },
                })}>
                    <option value=''>Select the promo</option>
                    {
                        promociones.map((promo, index) => <option key={index} value={promo}>{promo}</option>)
                    }
                </select>
                <span>{!!errors['promo'] && errors['promo'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Check-In'} icono={'date'} />
                <DatePicker
                    selected={watch('checkIn') !== '' ? new Date(watch('checkIn')) : new Date()}
                    onChange={(date) => {
                        try {
                            const fechaFormateada = formatearFecha(date);
                            setValue('checkIn', fechaFormateada)
                            clearErrors('checkIn')
                        } catch (error) {
                            setError('checkIn', { type: 'custom', message: 'Select the check-in day' })
                        }
                    }}
                    includeDateIntervals={[
                        { start: new Date(), end: addDays(new Date(), 30) },
                    ]}
                    placeholderText="Select the check-in day"
                    calendarClassName={isCalendarOpen ? "fixed-datepicker" : ""}
                    onCalendarOpen={handleCalendarOpen}
                    onCalendarClose={handleCalendarClose}
                />
                <span>{!!errors['checkIn'] && errors['checkIn'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Check-Out'} icono={'date'} />
                <DatePicker
                    selected={watch('checkOut') !== '' ? new Date(watch('checkOut')) : new Date()}
                    onChange={(date) => {
                        try {
                            const fechaFormateada = formatearFecha(date);
                            setValue('checkOut', fechaFormateada)
                            clearErrors('checkOut')
                        } catch (error) {
                            setError('checkOut', { type: 'custom', message: 'Select the check-out day' })
                        }
                    }}
                    includeDateIntervals={[
                        { start: new Date(), end: addDays(new Date(), 30) },
                    ]}
                    placeholderText="Select the check-out day"
                    calendarClassName={isCalendarOpen ? "fixed-datepicker" : ""}
                    onCalendarOpen={handleCalendarOpen}
                    onCalendarClose={handleCalendarClose}
                />
                <span>{!!errors['checkOut'] && errors['checkOut'].message}</span>
            </div>

            <div className='cont-input-item'>
                <LabelForm name={'Estimated Price'} icono={'price'} />
                <input style={{ textAlign: 'center' }} readOnly type='text' value={estimatePrice} />
            </div>

        </div>
        {isLoading ?
            <div className='cont-btn-loading'>
                <Puff
                    visible={true}
                    height="40"
                    width="40"
                    color="#E51F5F"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <p>Processing...</p>
            </div>
            : <div className='cont-btn-book'>
                <button className='btn-send'>Book Now</button>
                {/*<p>Get <strong>10% Discount</strong>, Ask Us How! </p>*/}
            </div>}
    </form>
}