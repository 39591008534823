const ButtonBanner = ({ children, title, url, targetBlank = true, isButton = false, onClick }) => {
    return isButton ?
        <button className="button-action-banner" onClick={onClick}>
            <div className="button-action-banner-icon">
                {children}
            </div>
            <p>{title}</p>
        </button>
        : <a className="button-action-banner" href={url} target={targetBlank ? `_blank` : ''}>
            <div className="button-action-banner-icon">
                {children}
            </div>
            <p>{title}</p>
        </a>
}

export default ButtonBanner