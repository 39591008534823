import ButtonsBannerContainer from "./components/ButtonsBannerContainer";
import FormularioRegistro from "./components/FormularioRegistro";
import FormularioRegistroFooter from "./components/FormularioRegistroFooter";
import PatasTransp from "./components/PatasTransp";
import Resenas from "./components/Resenas";
import SeccionCaruselPets from "./components/SeccionCaruselPets";
import SeccionFaqs from "./components/SeccionFaqs";
import TitleSection from "./components/TitleSection";


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Stress-Free Pet Boarding: Safe, Cozy, and Loving Care While You’re Away.</h1>
      </header>
      <div className="banner-principal">
        <video className="background-video" autoPlay loop muted>
          <source src="https://tlc-pcs.com/wp-content/uploads/2024/11/tlc-pet-care-5.mp4" type="video/mp4" />
          Your browser does not support the video tag. </video>
        <div className="overlay-content"> {/* Añade aquí los elementos que quieras superponer */}
          <FormularioRegistro />
          <ButtonsBannerContainer />
        </div>
      </div>
      <TitleSection title='Your Fur Babies’ Happy Days Await at TLC!' spaceTop={20} spaceBottom={20} />
      <SeccionCaruselPets />
      <TitleSection title='Our Services' spaceTop={20} spaceBottom={20} />
      <SeccionFaqs />
      <Resenas />
      <div className="footer">
        <div className="footer-iz">
          <a className="footer-tel" href="tel:9542955050">
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
            </div>
            <p>954-295-5050</p>
          </a>
          <div className="cont-form-footer">
            <FormularioRegistroFooter />
          </div>
        </div>
        <div className="footer-dr">
          <a className="footer-tel" href="https://maps.app.goo.gl/dxNoSZehEokKhYiZ7" target="_blank" rel="noreferrer">
            <div className="icon" style={{ width: 20 }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="white" d="M16 144a144 144 0 1 1 288 0A144 144 0 1 1 16 144zM160 80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-53 0-96 43-96 96c0 8.8 7.2 16 16 16s16-7.2 16-16c0-35.3 28.7-64 64-64zM128 480l0-162.9c10.4 1.9 21.1 2.9 32 2.9s21.6-1 32-2.9L192 480c0 17.7-14.3 32-32 32s-32-14.3-32-32z"/></svg>
            </div>
            <p>Located At</p>
          </a>
          <iframe className="iframe-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.0631005432538!2d-80.13952992404863!3d26.096858794673985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9006474671971%3A0x4d9d3743afeed930!2s1923%20S%20Federal%20Hwy%2C%20Fort%20Lauderdale%2C%20FL%2033316%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sec!4v1731735695818!5m2!1ses-419!2sec" width="450" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="1923 S Federal Hwy, Fort Lauderdale, FL 33316"></iframe>
          <p className="text-map">1923 S Federal Hwy, Fort Lauderdale, FL 33316</p>
          <PatasTransp />
        </div>
      </div>
    </div>
  );
}

export default App;
