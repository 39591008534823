/*export const servicios = [
  { talla: '1-15 Lbs', nombre: 'Luxy Small Suite (3’x3’)', precio: 45, fullName: '1-15 Lbs --> Luxy Small Suite (3’x3’) --> $45' },
  { talla: '16-40 Lbs', nombre: 'Luxury Medium Suite (3’x4’)', precio: 50, fullName: '16-40 Lbs --> Luxury Medium Suite (3’x4’) --> $50' },
  { talla: '41-70 Lbs', nombre: 'Luxury Large Suite 4’x6’', precio: 55, fullName: '41-70 Lbs --> Luxury Large Suite 4’x6’ --> $55 ' },
  { talla: '71+ Lbs', nombre: 'Deluxe Semi-Presidential 6’x8’', precio: 75, fullName: '71+ Lbs --> Deluxe Semi-Presidential 6’x8’ --> $75' },
  { talla: '71+ Lbs', nombre: 'Deluxe Presidential Suite 8’x8’', precio: 80, fullName: '71+ Lbs --> Deluxe Presidential Suite 8’x8’ --> $80' },
  { talla: '--', nombre: '1 Doggie Daycare Day', precio: 35, fullName: '1 Doggie Daycare Day --> $35' },
  { talla: '--', nombre: '5 Doggie Daycare Day', precio: 140, fullName: '5 Doggie Daycare Day --> $140' },
  { talla: '--', nombre: 'Pet Taxi up to 10 Miles', precio: 30, fullName: 'Pet Taxi up to 10 Miles --> $30' },
  { talla: '--', nombre: 'Full Grooming starting', precio: 75, fullName: 'Full Grooming starting --> $75' }
]*/

export const servicios = [
  { talla: '1-15 Lbs', nombre: 'Luxy Small Suite (3’x3’)', precio: 45, fullName: '1-15 Lbs --> Luxy Small Suite (3’x3’)' },
  { talla: '16-40 Lbs', nombre: 'Luxury Medium Suite (3’x4’)', precio: 50, fullName: '16-40 Lbs --> Luxury Medium Suite (3’x4’)' },
  { talla: '41-70 Lbs', nombre: 'Luxury Large Suite 4’x6’', precio: 55, fullName: '41-70 Lbs --> Luxury Large Suite 4’x6’' },
  { talla: '71+ Lbs', nombre: 'Deluxe Semi-Presidential 6’x8’', precio: 75, fullName: '71+ Lbs --> Deluxe Semi-Presidential 6’x8’' },
  { talla: '71+ Lbs', nombre: 'Deluxe Presidential Suite 8’x8’', precio: 80, fullName: '71+ Lbs --> Deluxe Presidential Suite 8’x8’' },
  { talla: '--', nombre: '1 Doggie Daycare Day', precio: 35, fullName: '1 Doggie Daycare Day' },
  { talla: '--', nombre: '5 Doggie Daycare Day', precio: 140, fullName: '5 Doggie Daycare Day' },
  { talla: '--', nombre: 'Pet Taxi up to 10 Miles', precio: 30, fullName: 'Pet Taxi up to 10 Miles' },
  { talla: '--', nombre: 'Full Grooming starting', precio: 75, fullName: 'Full Grooming starting' }
]

export const promociones = [
  'Book 2 Nights, Get $20 OFF',
  'Book 3 Nights, Get a FREE Exit Bath',
  'Book 3 Nights, Get 4th Night FREE',
  'Book 7 Nights, Get 15% OFF and FREE Exit Bath',
  'Book 14 Nights, Get 20% OFF & FREE Exit Bath',
  'Book 21+ Nights, Get 30% OFF & FREE Exit Bath',
  ]