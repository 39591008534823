const PatasTransp = () => {
    return <div className="patas-transp">
        <div className="patas2">
            <svg viewBox="0 0 321 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g >
                    <path d="M44.1139 175.572C37.3578 177.308 28.977 171.85 25.3948 163.382C21.8126 154.914 24.3856 146.642 31.1416 144.906C37.8977 143.17 46.2785 148.628 49.8607 157.096C53.4429 165.564 50.8699 173.836 44.1139 175.572Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M39.0631 186.12C43.054 191.646 42.4519 198.062 37.6956 200.455C32.9597 202.853 25.8632 200.301 21.8601 194.782C17.8692 189.256 18.4713 182.84 23.2276 180.447C27.9839 178.055 35.06 180.601 39.0631 186.12Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M78.2855 168.409C71.5294 170.145 63.1486 164.687 59.5664 156.219C55.9843 147.751 58.5572 139.479 65.3133 137.743C72.0693 136.007 80.4501 141.465 84.0323 149.933C87.6145 158.401 85.0416 166.673 78.2855 168.409Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M100.645 187.431C94.9223 187.101 89.8822 181.756 89.3872 175.494C88.8922 169.232 93.1297 164.423 98.852 164.753C104.574 165.083 109.614 170.427 110.109 176.69C110.604 182.952 106.367 187.761 100.645 187.431Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M85.5702 235.488C84.7224 235.671 83.7159 235.787 82.5983 235.762C80.092 235.719 78.1879 234.961 74.4649 233.452C72.9025 232.816 70.8753 231.924 68.5561 230.671C67.4409 231.153 65.4499 231.85 62.7664 231.88C61.706 231.892 58.3297 231.88 54.7961 229.979C50.7406 227.785 48.9658 224.436 48.1651 222.889C44.6561 216.203 47.0003 210.129 47.7217 208.271C48.29 206.786 49.3107 204.268 52.0027 202.02C54.0258 200.339 56.1647 199.502 57.5841 199.062C57.6656 198.463 57.7308 197.528 57.502 196.398C57.2041 194.925 56.5714 193.868 56.3645 193.513C54.8981 190.861 54.4743 183.252 59.6863 179.892C62.8544 177.845 66.6972 178.26 67.1677 178.334C74.0196 179.178 79.1834 185.797 79.8624 188.602C79.9562 188.981 80.1729 190.117 80.9998 191.486C81.6242 192.531 82.3504 193.295 82.8664 193.772C84.3822 193.595 86.8137 193.479 89.7363 194.115C93.6494 194.971 96.3513 196.719 97.9066 197.752C99.8701 199.053 106.263 203.295 108.137 210.317C108.566 211.937 109.505 215.463 107.658 218.891C106.044 221.873 103.19 223.187 102.276 223.584C99.9816 224.598 97.7858 224.776 96.4866 224.794C95.4222 226.743 94.3529 228.301 93.4927 229.426C91.4381 232.139 90.3746 233.514 88.2743 234.528C87.8399 234.748 86.8898 235.164 85.5372 235.439L85.5702 235.488Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M162.53 109.432C150.947 113.5 136.534 105.678 130.337 91.9617C124.141 78.2452 128.507 63.8281 140.09 59.7603C151.673 55.6925 166.086 63.5143 172.282 77.2309C178.479 90.9474 174.113 105.364 162.53 109.432Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M153.925 128.114C160.806 136.787 159.81 147.737 151.663 152.579C143.55 157.427 131.358 154.303 124.456 145.643C117.576 136.97 118.571 126.019 126.719 121.178C134.866 116.336 147.023 119.453 153.925 128.114Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M221.124 91.5926C209.541 95.6604 195.128 87.8385 188.932 74.122C182.735 60.4055 187.101 45.9884 198.684 41.9206C210.267 37.8528 224.68 45.6746 230.877 59.3912C237.073 73.1077 232.707 87.5247 221.124 91.5926Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M259.603 120.005C249.782 120.406 241.102 112.216 240.216 101.711C239.33 91.2055 246.573 82.3644 256.394 81.9632C266.215 81.5621 274.895 89.7529 275.781 100.258C276.667 110.763 269.424 119.604 259.603 120.005Z" fill="#FFA1BD" />
                </g>
                <g >
                    <path d="M234.019 203.783C232.565 204.235 230.838 204.6 228.921 204.746C224.62 205.093 221.348 204.131 214.951 202.203C212.266 201.39 208.782 200.221 204.795 198.491C202.884 199.495 199.472 201.006 194.867 201.507C193.048 201.706 187.254 202.25 181.18 199.629C174.208 196.599 171.143 191.234 169.759 188.754C163.699 178.038 167.686 167.375 168.913 164.113C169.879 161.507 171.616 157.079 176.222 152.826C179.684 149.646 183.349 147.872 185.782 146.89C185.918 145.864 186.025 144.272 185.625 142.399C185.106 139.959 184.014 138.277 183.657 137.713C181.125 133.474 180.353 120.681 189.277 114.125C194.701 110.133 201.297 110.192 202.105 110.237C213.868 110.515 222.767 120.84 223.949 125.469C224.112 126.094 224.491 127.979 225.918 130.155C226.995 131.817 228.246 132.987 229.134 133.707C231.734 133.153 235.906 132.55 240.924 133.135C247.644 133.926 252.291 136.428 254.966 137.914C258.342 139.785 269.337 145.885 272.594 157.443C273.34 160.109 274.972 165.914 271.822 172.02C269.07 177.333 264.181 180.032 262.615 180.857C258.684 182.956 254.917 183.624 252.688 183.873C250.873 187.347 249.047 190.161 247.578 192.206C244.068 197.138 242.251 199.641 238.653 201.708C237.909 202.153 236.281 203.014 233.962 203.706L234.019 203.783Z" fill="#FFA1BD" />
                </g>
            </svg>

        </div>
        <div className="cont-patas-contact">
            <svg viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M34.1863 50.5461C29.801 53.0875 22.9804 50.9451 18.9521 45.7609C14.9237 40.5768 15.2131 34.314 19.5985 31.7727C23.9838 29.2313 30.8044 31.3737 34.8327 36.5579C38.861 41.742 38.5716 48.0048 34.1863 50.5461Z" fill="#FFA1BD" />
                </g>
                <g>
                    <path d="M32.586 58.8818C36.3649 61.9381 37.1101 66.5213 34.235 69.1243C31.3752 71.7275 25.9754 71.3534 22.1891 68.3037C18.4103 65.2474 17.6651 60.6642 20.5401 58.0611C23.4151 55.4581 28.7998 55.8321 32.586 58.8818Z" fill="#FFA1BD" />
                </g>
                <g>
                    <path d="M56.6602 38.8176C52.2749 41.359 45.4543 39.2166 41.426 34.0324C37.3977 28.8483 37.6871 22.5855 42.0724 20.0442C46.4577 17.5028 53.2783 19.6452 57.3066 24.8294C61.3349 30.0135 61.0455 36.2763 56.6602 38.8176Z" fill="#FFA1BD" />
                </g>
                <g>
                    <path d="M75.6663 47.6379C71.6254 48.5378 67.1495 45.8145 65.6691 41.5555C64.1887 37.2964 66.2643 33.1143 70.3052 32.2145C74.3461 31.3147 78.8221 34.0379 80.3025 38.2969C81.7829 42.556 79.7072 46.7381 75.6663 47.6379Z" fill="#FFA1BD" />
                </g>
                <g>
                    <path d="M73.8968 84.0468C73.3403 84.3416 72.6611 84.6211 71.8791 84.8246C70.1277 85.2894 68.6655 85.138 65.8015 84.8225C64.5992 84.6887 63.027 84.468 61.1862 84.0541C60.4979 84.61 59.2392 85.4876 57.3777 86.0384C56.6422 86.2562 54.291 86.914 51.4878 86.289C48.2684 85.563 46.4261 83.5834 45.5885 82.6657C41.9344 78.7069 42.4633 74.0182 42.6282 72.5833C42.7541 71.438 43.0075 69.4849 44.4724 67.3891C45.575 65.8207 46.9113 64.8161 47.819 64.2299C47.767 63.7971 47.6427 63.1338 47.2784 62.3924C46.804 61.4265 46.172 60.8158 45.9638 60.6101C44.4624 59.0542 42.7873 53.8446 45.8037 50.4777C47.6364 48.4282 50.3854 47.9589 50.726 47.9171C55.646 47.1517 60.4391 50.7372 61.4204 52.5547C61.5544 52.7998 61.9114 53.5478 62.735 54.337C63.359 54.9408 64.0028 55.329 64.4483 55.5587C65.4707 55.1363 67.1412 54.5757 69.2899 54.4411C72.1675 54.2641 74.3643 54.9469 75.6338 55.3585C77.236 55.8766 82.4529 57.5657 85.0303 62.0811C85.6227 63.123 86.9158 65.3909 86.2525 68.1406C85.6706 70.5339 83.9237 72.0111 83.36 72.4678C81.9476 73.6264 80.452 74.1831 79.5515 74.4522C79.1647 76.0185 78.7034 77.3137 78.309 78.2657C77.3718 80.5589 76.8813 81.7254 75.6041 82.8457C75.3418 83.0844 74.7562 83.561 73.865 84.0193L73.8968 84.0468Z" fill="#FFA1BD" />
                </g>
            </svg>
        </div>
    </div>
}

export default PatasTransp